<template>
  <div id="login" class="d-flex align-items-center justify-content-center position-relative">
    <div>
      <div class="row login-content">
        <div id="tabuleiroIMG" class="col-md-7 position-relative p-0 imagem-login d-flex j-c-center">
          <div class="card-text-login p-4">
              <p class="text-card-login">Aprenda online as <b>melhores</b> técnicas de xadrez para <b>dominar</b> a sua partida.
              </p>
          </div>
          <img class="powered-faster" src="@/assets/powered-by-fasters.png" alt="Desenvolvido pela Fasters Soluções em Tecnologia">
        </div>
        <div
          class="col-md-5 d-flex align-items-center justify-content-center p-0 form-login"
        >
          <div>
            <img id="logo" src="@/assets/logo-skaki.png" alt="logo.png" />
            <h2 class="mt-3 color-0e5caf"><b>Entrar</b></h2>
            <div class="mt-4 mb-3">
              <i class="fas fa-chess-knight"></i>
              <span class="pl-2"><b>Xadrez, a ginástica da mente.</b></span>
            </div>
            <ValidationObserver
              ref="form"
              tag="form"
              class="row"
              @submit.prevent="logar()"
              v-on:keyup.enter="logar()"
            >
              <ValidationProvider
                v-if="wayLogin == 0"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required|validadeCpf"
                name="CPF"
                :bails="false"
                class="col-md-12 input-padding"
                tag="div"
              >
                <div class="control input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-transparent border-right-0" id="basic-addon1"><i class="fas fa-user"></i></span>
                  </div>
                  <input
                    v-model="formLogar.cpf"
                    placeholder="CPF"
                    v-mask="'###.###.###-##'"
                    class="form-control inputs-login border-left-0 border-right-0 inputs-login"
                    type="text"
                    id="txbCpf"
                  />
                   <div @click.prevent="changeCpfCod" class="input-group-append">
                      <span role="button" class="input-group-text bg-transparent border-left-0">
                          <i class="fas fa-exchange-alt"></i>
                      </span>
                    </div>
                  </div>
                  <div class="text-left">
                    <span v-bind="ariaMsg" class="span-status-validate">{{
                      errors[0]
                    }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                v-if="wayLogin == 1"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="Código"
                :bails="false"
                class="col-md-12 input-padding"
                tag="div"
              >
                <div class="control input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-transparent border-right-0" id="basic-addon1"><i class="fas fa-user"></i></span>
                  </div>
                  <input
                    placeholder="Código"
                    v-mask="'XX.XX.XX.XX.XX.XX.XX.XX.XX.XX.XX.XX'"
                    class="form-control inputs-login border-left-0 border-right-0 inputs-login"
                    type="text"
                    v-model="formLogar.cod"
                    id="txbCpf"
                  />
                    <div @click.prevent="changeCpfCod" class="input-group-append">
                      <span role="button" class="input-group-text bg-transparent border-left-0">
                          <i class="fas fa-exchange-alt"></i>
                      </span>
                    </div>
                </div>
                <div class="text-left">
                  <span v-bind="ariaMsg" class="span-status-validate">{{
                    errors[0]
                  }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="'Senha'"
                :bails="false"
                :immediate="true"
                tag="div"
                class="col-md-12 mt-3"
              >
                <revelaSenha
                  placeholder="Senha"
                  type="password"
                  v-model="formLogar.password"
                />
                <div class="text-left">
                  <span v-bind="ariaMsg" class="span-status-validate">{{errors[0]}}</span>
                </div>
              </ValidationProvider>
              <!-- <div class="w-100 d-flex j-c-center mt-2 mb-4 checker">
                <div class="Ochecker d-flex j-c-center" @click="checking">
                  <img
                    class="checker-img d-block"
                    src="@/assets/icones/Check.png"
                    alt="Check.png"
                    style="margin-bottom: 4px; margin-left: 1px;"
                  />
                </div>
                <div>
                  <p class="ml-3">Manter minha conta conectada</p>
                </div>
              </div> -->
              <div class="col-md-12">
                <button
                  type="submit"
                  class="bg-0e5caf f-w-700 color-fff button-login mt-4 mb-3"
                  :disabled="disabled"
                >
                  {{ statusLogin }}
                  <div v-if="show" class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </button>
                <div class="d-flex justify-content-around c-center">
                  <div class="linha"></div>
                  <small>Ou</small>
                  <div class="linha"></div>
                </div>
                <button
                  class="bg-fff f-w-700 color-0e5caf button-contact mt-3"
                  :disabled="disabled"
                  @click.prevent="$router.push('/login/contato')"
                >
                  Solicite uma conta
                </button>
              </div>
            </ValidationObserver>
          </div>
          <footer class="auth-footer d-flex justify-content-between c-center">
            <p class="mr-5 text-secondary">
              {{ currentYear }} © Todos os direitos reservados - Projeto Skaki
              Xadrez
            </p>
            <!-- <i role="button" class="icones-footer fab fa-facebook-f"></i>
            <i role="button" class="icones-footer fab fa-facebook-messenger"></i> -->
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import theTabuleiro from "@/components/theTabuleiro";
import revelaSenha from "@/components/senha/revelaSenha";

export default {
  name: "Login",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      statusChecker: true,
      initialSound: require("@/assets/audio/inicouIntenso.wav"),
      statusLogin: "Entrar",
      show: false,
      disabled: false,
      wayLogin: 1,
      rotaTo: null,

      formLogar: {
        cpf: '',
        cod: '',
        password: null,
      },
      unidade: "",
      linkar: false,
      dadosExercicioVisitante: {
        fen: "2kr2nr/1pp2ppp/3b4/1P3q2/2Pp1B2/5Q1P/RP3PP1/R5K1 w - - 0 1",
        cor: "white",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserDatas"]),

  },
  components: {
    theTabuleiro,
    revelaSenha,
  },
  watch: {
    getUserDatas: function() {
      setTimeout(() => {
        if (this.getUserDatas.profile_id != 3) {
          this.$router.push(
            `/${this.verifyStatus(
              this.getUserDatas.profile_id
            ).toLowerCase()}/inicial`
          );
        } else {
          this.getCoordenadorUnidade(this.getUserDatas.unidade_id);
        }
      }, 500);
    },
  },
  methods: {
    carregaUsuarios() {
      if (this.$store.getters.getUserDatas.profile_id != 5) {
        this.$store
          .dispatch(
            "getDadosDeTodosUsuarios",
            this.$store.getters.getPerfilToken
          )
          .then((resolve) => {
            if (resolve.valid) {
              this.toastGlobal("success", `Dados carregados com sucesso`);
            } else {
              this.toastGlobal(
                "error",
                `Algo de errado ocorreu, alguns dados não foram carregados ${resolve.msg.responseJSON.msg}`
              );
            }
          });
      }
    },
    checking() {
      if ($(".checker-img").hasClass("d-block")) {
        $(".checker-img")
          .removeClass("d-block")
          .addClass("d-none");
        this.statusChecker = false;
      } else {
        $(".checker-img")
          .removeClass("d-none")
          .addClass("d-block");
        this.statusChecker = true;
      }
    },
    // teste(){
    //     this.logar()
    // },
    logar() {
      this.disabled = true;
      if (this.wayLogin == 0) delete this.formLogar.cod;
      else if (this.wayLogin == 1) delete this.formLogar.cpf;
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.disabled = true;
          this.$store;
          this.statusLogin = "";
          this.show = true;
          if (this.formLogar.cod)
            this.formLogar.cod = this.formLogar.cod.replace(/[^\d]+/g, "");
          if (this.formLogar.cpf)
            this.formLogar.cpf = this.formLogar.cpf.replace(/[^\d]+/g, "");
          this.$store.dispatch("login", this.formLogar).then((resolve) => {
            this.disabled = false;
            if (resolve.valid) {
              this.carregaUsuarios();
              this.$nextTick(() => {
                setTimeout(() => {
                  if (this.getUserDatas.name) {
                    this.toastGlobal(
                      "success",
                      `Bem-vindo ao Skaki ${this.getUserDatas.name}`
                    );
                  }
                  this.linkar = true;
                }, 1000);
                if (!this.$store.getters.getOkShowContent) {
                  setInterval(() => {
                    let theLogo = document.getElementById('theLogoImgOnLoad')
                    theLogo.style.width = `${theLogo.offsetWidth + 50}px`
                  }, 1);
                  setInterval(() => {
                    let theLoader = document.getElementById('el__loaderBar')
                    theLoader.style.width = '25%'
                  }, 1125);
                }
                // setTimeout(() => {
                  //   //this.playSong(this.initialSound);
                // }, 1000);
                setTimeout(() => {
                  this.$store.commit("SET_OK_SHOW_CONTENT", true)  
                  setTimeout(() => {
                    location.reload();
                  }, 1);
                }, 4500);
              });
            } else {
              this.toastGlobal("error", resolve.msg.responseJSON.msg);
            }
            (this.statusLogin = "Entrar"), (this.show = false);
          });
        }
        this.disabled = false;
      });
    },
    getCoordenadorUnidade(id) {
      $.ajax({
        async: true,
        type: "GET",
        url: `${this.VUE_APP_CMS}api/unidades/${id}`,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.$store.commit("SET_UNIDADE_USER", response.name);
          this.$router.push(
            `/${this.verifyStatus(
              this.$store.getters.getUserDatas.profile_id
            ).toLowerCase()}/${this.$store.getters.getUserDatas.unidade_id}/${
              response.name
            }`
          );
        },
      });
    },
    changeCpfCod() {
      this.formLogar.cpf = null;
      this.formLogar.cod = null;
      this.wayLogin = this.wayLogin == 0 ? 1 : 0;
    },
  },
};
</script>

<style scoped>
@import "../style/telasDeLogin.css";

.linha {
  background: lightgray;
  height: 1px;
  width: 40%;
}

.centraliza-absolute {
  font-size: 27px;
  line-height: 1.8;
}

.form-login {
  padding: 7% 10% 10% 10% !important;
}

#tabuleiroIMG {
  background-color: rgb(196, 222, 251);
}

#changeCpfCod,
#changeCodCpf {
  width: 40px;
  height: 40px;
  right: -55px;
  border-radius: 5px;
}

#changeCpfCod:hover,
#changeCodCpf:hover {
  cursor: pointer;
}

.button-contact {
  border-radius: 14px !important;
  border: 1px solid #0e5caf
}
</style>
