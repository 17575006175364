<template>
  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text bg-transparent border-right-0"><i class="fas fa-lock"></i></span>
  </div>
  <input 
    v-model="teste" 
    :type="inputType" 
    :placeholder="placeholder"
    @change="$emit('input', $event.target.value)"
    
    class="form-control border-left-0 border-right-0 inputs-login" aria-label="Amount (to the nearest dollar)">
  <div @click.prevent="togglePassword()" class="input-group-append">
    <span role="button" class="input-group-text bg-transparent border-left-0">
        <i v-if="isPassword" class="far fa-eye"></i>
        <i v-else class="far fa-eye-slash"></i>
    </span>
  </div>
</div>
</template>

<script>
export default {
  name: "PasswordRevealing",

  props: {
    value: {
      type: String,
      default: "",
    },

    name: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      inputType: "password",
      teste: null,
    };
  },

  computed: {
    isPassword() {
      return this.inputType === "password";
    },
  },

  methods: {
    togglePassword() {
      this.inputType = this.isPassword ? "text" : "password";
    },
  },
};
</script>
<style scoped>
.principal {
  position: relative;
  width: 100%;
}
.input-principal {
  width: 100%;
}
.olho {
  cursor: pointer;
  position: absolute;
  top: 27%;
  right: 15px;
}
</style>
