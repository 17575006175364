var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center position-relative",attrs:{"id":"login"}},[_c('div',[_c('div',{staticClass:"row login-content"},[_vm._m(0),_c('div',{staticClass:"col-md-5 d-flex align-items-center justify-content-center p-0 form-login"},[_c('div',[_c('img',{attrs:{"id":"logo","src":require("@/assets/logo-skaki.png"),"alt":"logo.png"}}),_vm._m(1),_vm._m(2),_c('ValidationObserver',{ref:"form",staticClass:"row",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.logar()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logar()}}},[(_vm.wayLogin == 0)?_c('ValidationProvider',{staticClass:"col-md-12 input-padding",attrs:{"rules":"required|validadeCpf","name":"CPF","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('div',{staticClass:"control input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text bg-transparent border-right-0",attrs:{"id":"basic-addon1"}},[_c('i',{staticClass:"fas fa-user"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLogar.cpf),expression:"formLogar.cpf"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control inputs-login border-left-0 border-right-0 inputs-login",attrs:{"placeholder":"CPF","type":"text","id":"txbCpf"},domProps:{"value":(_vm.formLogar.cpf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLogar, "cpf", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",on:{"click":function($event){$event.preventDefault();return _vm.changeCpfCod.apply(null, arguments)}}},[_c('span',{staticClass:"input-group-text bg-transparent border-left-0",attrs:{"role":"button"}},[_c('i',{staticClass:"fas fa-exchange-alt"})])])]),_c('div',{staticClass:"text-left"},[_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])])]}}],null,false,4133936346)}):_vm._e(),(_vm.wayLogin == 1)?_c('ValidationProvider',{staticClass:"col-md-12 input-padding",attrs:{"rules":"required","name":"Código","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('div',{staticClass:"control input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text bg-transparent border-right-0",attrs:{"id":"basic-addon1"}},[_c('i',{staticClass:"fas fa-user"})])]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('XX.XX.XX.XX.XX.XX.XX.XX.XX.XX.XX.XX'),expression:"'XX.XX.XX.XX.XX.XX.XX.XX.XX.XX.XX.XX'"},{name:"model",rawName:"v-model",value:(_vm.formLogar.cod),expression:"formLogar.cod"}],staticClass:"form-control inputs-login border-left-0 border-right-0 inputs-login",attrs:{"placeholder":"Código","type":"text","id":"txbCpf"},domProps:{"value":(_vm.formLogar.cod)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLogar, "cod", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",on:{"click":function($event){$event.preventDefault();return _vm.changeCpfCod.apply(null, arguments)}}},[_c('span',{staticClass:"input-group-text bg-transparent border-left-0",attrs:{"role":"button"}},[_c('i',{staticClass:"fas fa-exchange-alt"})])])]),_c('div',{staticClass:"text-left"},[_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])])]}}],null,false,3031881914)}):_vm._e(),_c('ValidationProvider',{staticClass:"col-md-12 mt-3",attrs:{"rules":"required","name":"'Senha'","bails":false,"immediate":true,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('revelaSenha',{attrs:{"placeholder":"Senha","type":"password"},model:{value:(_vm.formLogar.password),callback:function ($$v) {_vm.$set(_vm.formLogar, "password", $$v)},expression:"formLogar.password"}}),_c('div',{staticClass:"text-left"},[_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"bg-0e5caf f-w-700 color-fff button-login mt-4 mb-3",attrs:{"type":"submit","disabled":_vm.disabled}},[_vm._v(" "+_vm._s(_vm.statusLogin)+" "),(_vm.show)?_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"})]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-around c-center"},[_c('div',{staticClass:"linha"}),_c('small',[_vm._v("Ou")]),_c('div',{staticClass:"linha"})]),_c('button',{staticClass:"bg-fff f-w-700 color-0e5caf button-contact mt-3",attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/login/contato')}}},[_vm._v(" Solicite uma conta ")])])],1)],1),_c('footer',{staticClass:"auth-footer d-flex justify-content-between c-center"},[_c('p',{staticClass:"mr-5 text-secondary"},[_vm._v(" "+_vm._s(_vm.currentYear)+" © Todos os direitos reservados - Projeto Skaki Xadrez ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-7 position-relative p-0 imagem-login d-flex j-c-center",attrs:{"id":"tabuleiroIMG"}},[_c('div',{staticClass:"card-text-login p-4"},[_c('p',{staticClass:"text-card-login"},[_vm._v("Aprenda online as "),_c('b',[_vm._v("melhores")]),_vm._v(" técnicas de xadrez para "),_c('b',[_vm._v("dominar")]),_vm._v(" a sua partida. ")])]),_c('img',{staticClass:"powered-faster",attrs:{"src":require("@/assets/powered-by-fasters.png"),"alt":"Desenvolvido pela Fasters Soluções em Tecnologia"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"mt-3 color-0e5caf"},[_c('b',[_vm._v("Entrar")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 mb-3"},[_c('i',{staticClass:"fas fa-chess-knight"}),_c('span',{staticClass:"pl-2"},[_c('b',[_vm._v("Xadrez, a ginástica da mente.")])])])}]

export { render, staticRenderFns }