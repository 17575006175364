import { render, staticRenderFns } from "./revelaSenha.vue?vue&type=template&id=268c14f9&scoped=true&"
import script from "./revelaSenha.vue?vue&type=script&lang=js&"
export * from "./revelaSenha.vue?vue&type=script&lang=js&"
import style0 from "./revelaSenha.vue?vue&type=style&index=0&id=268c14f9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268c14f9",
  null
  
)

export default component.exports